import React, { Component } from 'react'

import { Redirect } from 'react-router-dom'
import Toolbar from '../components/Toolbar'
import DashboardTicketCard from '../components/DashboardTicketCard'
import Booked from '../components/stats/Booked'
import Location from '../components/stats/Locations'
import Progress from '../components/stats/Progress'
import Capacity from '../components/stats/Capacity'
import Loading from '../components/Loading'

import moment from 'moment'
import 'moment/locale/zh-tw'

import Slider from 'react-slick'
import 'c3/c3.css'

import '../dashboardLayout.css'

import API from '../api'

moment.locale('zh-tw')

export default class C extends Component {
  constructor (props) {
    super(props)

    let u = window.localStorage.getItem('loggedIn')
    if (u) u = JSON.parse(u)
    this.state = {
      user: u,
      stats: null,
      loading: true,
      showChart: false,
    }
  }

  async loadStats () {
    let newComing = []
    try {
      newComing = await API.getNewComing(this.state.user)
      console.log('newComing is =>', newComing)

      let ret = await API.getDashboard(this.state.user, moment().format('YYYY-MM-DD'))
      console.log(ret)

      // c3.js doesn't reload data properly when working with react
      // Therefore we force destory the Chart component via setting showChart to false, then re-create it.
      this.setState({ showChart: false }, () => {
        this.setState({ stats: ret, loading: false, showChart: true, newComing })
      })

    } catch (e) {
      console.error(e)
      if (e.response.status === 401) {
        window.localStorage.clear();
        this.setState({user: null})
      }
    }
  }

  componentDidMount () {
    this.loadStats()

    this.timer = setInterval(() => {
      this.loadStats()
    }, 3 * 60 * 1000)
  }

  componentWillReceiveProps () {
    this.setState({ loading: true })
    this.loadStats()
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  render () {
    if (!this.state.user) {
      return (<Redirect to={{ pathname: '/' }} />)
    }

    if (this.state.loading) {
      return (<div>
        <Toolbar active='stats' />
        <div className='h-screen' style={{ marginTop: '49px' }} >
          <div className='w-full h-64 flex justify-center items-center'>
            <Loading type='bubbles' color='#3E47F9' />
          </div>
        </div>
      </div>)
    }

    let stats = this.state.stats;
    console.log('stats is =>', stats);
    let sliderSettings = {
      dots: true,
      arrows: false,
      centerMode: true,
      centerPadding: '16px',
      infinite: false,
    }

    let mapSliderSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay:true,
      className: "mapSlider"
    }

    return (
      <div>
        <Toolbar active='stats' />
        <div className='' style={{ marginTop: '49px' }} >
          <div className='pt-6 dashboard' style={{ backgroundColor: '#f4f4f4' }}>
            <div className='stats-slick'>
              <Slider {...sliderSettings}>
                <div>
                  <Booked
                    className='dashboard-booked-slick'
                    showChart={this.state.showChart}
                    total={stats.booked.total}
                    arrived={stats.booked.arrived}
                    waiting={stats.booked.on_the_way}
                    reserved={stats.booked.booked}
                    unreserved={stats.nonbooked.total} />
                  <Capacity
                    className='dashboard-capacity-slick'
                    showChart={this.state.showChart}
                    loading_occupied={stats.maintenance_capacity.loading.occupied}
                    loading_capacity={stats.maintenance_capacity.loading.capacity}
                    left={stats.left_car_number}
                    deliver={stats.maintenance_capacity.delivery.delivered}
                    deliverGoal={stats.maintenance_capacity.delivery.target} />
                </div>
                
                <div className='bg-white overflow-hidde p-6 shadow border border-grey dashboard-progress-slick'>
                  <h4 className='text-grey-darker font-normal text-xl mb-1'>服務進度</h4>
                  <div className="flex-col"> 
                    <div className="pt-5">
                      <span className='text-base font-black py-2'>目前各進度車輛顯示</span>
                    </div>                   
                    <Progress 
                    recep={stats.progress.recep}
                    dispatch={stats.progress.dispatch}
                    repair={stats.progress.repair}
                    detail={stats.progress.detail}
                    deliver={stats.progress.deliver} />
                  </div>
                </div>

                <div className='bg-white overflow-hidde p-6 shadow border border-grey dashboard-location-slick'>
                  <h4 className='text-grey-darker font-normal text-xl mb-1'>廠內動態</h4>
                  <div className="flex-row">
                    <Slider {...mapSliderSettings}>
                      <Location
                        floor="1F"
                        recep={stats.areas._1F_RECEP.in} recepMax={stats.areas._1F_RECEP.capacity}
                        repair={stats.areas._1F_REPAIR.in} repairMax={stats.areas._1F_REPAIR.capacity}
                      />
                      <Location
                        floor="B1"
                        detail={stats.areas._B1_DETAIL.in} detailMax={stats.areas._B1_DETAIL.capacity}
                        deliver={stats.areas._B1_DELIVER.in} deliverMax={stats.areas._B1_DELIVER.capacity}
                      />
                    </Slider>
                  </div>                  
                </div>                
              </Slider>
              <div className='border-b mt-8 m-auto' style={{ width: '90vw' }} />
            </div>
            <Booked
              showChart={this.state.showChart}
              className='dashboard-booked'
              total={stats.booked.total}
              arrived={stats.booked.arrived}
              waiting={stats.booked.on_the_way}
              reserved={stats.booked.booked}
              unreserved={stats.nonbooked.total} />
            
            <div className={`bg-white overflow-hidden p-4 shadow border border-grey dashboard-locationSummary`}>
              <h4 className='text-grey-darker font-normal text-xl mb-4'>服務進度與廠內動態</h4>
              <div className="flex h-full">
                <div className="flex-row w-1/3">
                  <div className="flex-col">
                    <div className="pt-5">
                      <span className='text-base font-black py-2'>目前各進度車輛顯示</span>
                    </div>
                    <Progress 
                    recep={stats.progress.recep}
                    dispatch={stats.progress.dispatch}
                    repair={stats.progress.repair}
                    detail={stats.progress.detail}
                    deliver={stats.progress.deliver} />
                  </div>
                </div>
                <div className="flex-row w-2/3">
                  <Slider {...mapSliderSettings}>
                    <Location
                      floor="1F"
                      recep={stats.areas._1F_RECEP.in} recepMax={stats.areas._1F_RECEP.capacity}
                      repair={stats.areas._1F_REPAIR.in} repairMax={stats.areas._1F_REPAIR.capacity}
                    />
                    <Location
                      floor="B1"
                      detail={stats.areas._B1_DETAIL.in} detailMax={stats.areas._B1_DETAIL.capacity}
                      deliver={stats.areas._B1_DELIVER.in} deliverMax={stats.areas._B1_DELIVER.capacity}
                    />
                  </Slider>
                </div>
              </div>
            </div>
              <Capacity
              showChart={this.state.showChart}
              className='dashboard-capacity'
              loading_occupied={stats.maintenance_capacity.loading.occupied}
              loading_capacity={stats.maintenance_capacity.loading.capacity}
              left={stats.left_car_number}
              deliver={stats.maintenance_capacity.delivery.delivered}
              deliverGoal={stats.maintenance_capacity.delivery.target} />

            <div className='bg-white overflow-hidden p-3 shadow border border-grey dashboard-new-cars'>
              <h4 className='text-grey-darker font-normal text-xl'>進出廠車輛</h4>
              {this.state.newComing.length === 0
                ? <div className='w-full h-64 flex items-center justify-center flex-col mt-12'>
                  <img alt='' src='/recent_empty.svg' className='mb-4' />
                  <span className='text-grey-dark text-lg'>無進出廠車輛</span>
                </div> : ''}
              {this.state.newComing.slice(0,6).map((event, i) => <DashboardTicketCard key={i} comingEvent={event} {...this.props}/>)}
            </div>

          </div>
        </div>
      </div>
    )
  }
}
