import React, { Component } from 'react'

function checkState(currentValue, maxValue){
  if(!currentValue || currentValue == 0){
    return 'none';    
  }

  if(currentValue >= maxValue){
    return 'danger';
  }

  if(currentValue < maxValue){
    return 'safe';
  }
}

export default class C extends Component {  
  render () {    
    let state = {
      floor: this.props.floor,
      recep: this.props.recepMax ? checkState(this.props.recep, this.props.recepMax) : null,
      dispatch: this.props.dispatchMax ? checkState(this.props.dispatch, this.props.dispatchMax) : null,
      repair: this.props.repairMax ? checkState(this.props.repair, this.props.repairMax) : null,
      detail: this.props.detailMax ? checkState(this.props.detail, this.props.detailMax) : null,    
      deliver: this.props.deliverMax ? checkState(this.props.deliver, this.props.deliverMax) : null,
      reserve: this.props.reserveMax ? checkState(this.props.reserve, this.props.reserveMax) : null,
    }
    console.log(state);

    return (
        <div className={"relative flex"}>
          <img  src={`progress/${state.floor}-progress-bg.png`} />
          {state.recep !== null ? <img className="absolute top-0 left-0" src={`progress/${state.floor}-recep-${state.recep}.png`} /> : null}
          {state.repair !== null ? <img className="absolute top-0 left-0" src={`progress/${state.floor}-repair-${state.repair}.png`} /> : null}
          {state.dispatch !== null ? <img className="absolute top-0 left-0" src={`progress/${state.floor}-dispatch-${state.dispatch}.png`} /> : null}
          {state.detail !== null ? <img className="absolute top-0 left-0" src={`progress/${state.floor}-detail-${state.detail}.png`} /> : null}
          {state.deliver !== null ? <img className="absolute top-0 left-0" src={`progress/${state.floor}-deliver-${state.deliver}.png`} /> : null}
          {state.reserve !== null ? <img className="absolute top-0 left-0" src={`progress/${state.floor}-reserve-${state.reserve}.png`} /> : null}
        </div>        
    )
  }
}
